import styledComponentsCjs from 'styled-components'
import React, { useState, useRef, useEffect } from 'react'
import ScrollAnimation from 'react-animate-on-scroll';
import { useDevices } from '../CompJS';

export default function SplitScreen({data}) {
  
    const [m,t,d] = useDevices()

    return (
        <Style className={`block split-screen ${data.color} ${data.content_side} `}>
            <div className="content">
            <ScrollAnimation animateIn="fadeInDown" animateOnce> <div className='text' dangerouslySetInnerHTML={{__html: data.content}}></div> </ScrollAnimation>
                <div className="spacer m"></div>
                <ScrollAnimation animateIn="fadeInDown" animateOnce> <div className = 'my-btn'> 

                {data.buttons.map((button, index) => button.button.url && button.button.title &&(
                    
                     <a href = {button.button.url} className={`button ${data.button_color}`}>{button.button.title}</a> 
                ))}
                </div> </ScrollAnimation>
                
            </div>
            {(t || d) &&

                <div className="image" style={{backgroundImage: `url(${data.image.sizes.large})`}}></div>
            }

            {m &&
             
                <img className = "mobile-img" src = {data.image.sizes.large}></img>
            
            }
        </Style>
    )
}

const Style = styledComponentsCjs.section`
    
    display: flex;
    flex-direction: row-reverse;
    align-items: stretch;
    min-height: 50vh!important;
    
    .text > div {
        line-height: 1.8em;
    }

    .mobile-img {
        width: 100%;
    }

    .text > p {
        line-height: 1.8em;
    }

    .button {

        border: calc(1em / 19.2) solid transparent;

        &.red {
            background: #b0312a;
            color: white;
        }

        &.red:hover {
            border: calc(1em / 19.2) solid #b0312a;
            background: white;
            color: #3a3737;
        }
        &.white {
            background: white!important;
            color: #3a3737;
        }
        &.white:hover {
            background: #3a3737!important;
            color:white;
            
            
        }

    }
    .image {
        flex-shrink: 0;
        width: 60%;
        background-position: center;
        background-size: cover;

        
    }

    &.left {
        flex-direction: row;
        

        .image {
            width: 40%;
        }
    }

    .content {
        width: 60%;
        padding: 5.5em;
        color: var(--color);
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-width: 70%;

        h2 {
            padding-bottom: 1em;
            position: relative;

            &:not(:first-child) {
                margin-top: 1.5em;
            }

            &:after {
                content: '';
                position: absolute;
                bottom: 0.5em;
                left: 0;
                width: 2em;
                height: 0.05em;
                background: var(--color);
            }
        }
    }




    &.white {
        background: white;
        --color: var(--color-secondary);
    }

    &.red {
        background: var(--color-primary);
        --color: white;
    }

    &.dark {
        background: var(--color-secondary);
        --color: white;
    }


    .tablet & {
        hyphens: auto;
        .image, .content {
            width: 50%;
        }
    }

    .mobile & {


        hyphens: auto;
        flex-direction: column;

        .image {
            width: 100%;
            height: 30em;
            background-size: contain;
            background-repeat: no-repeat;
        }

        .content {
            width: 100%!important;
            max-width: 100%!important;
        }
    }

    

    .fade-in-section {
        opacity: 0;
        transform: translateY(20vh);
        visibility: hidden;
        transition: opacity 0.6s ease-out, transform 1.2s ease-out;
        will-change: opacity, visibility;
      }
      .fade-in-section.is-visible {
        opacity: 1;
        transform: none;
        visibility: visible;
      }

`
