import React, { useEffect, useState } from 'react'
import Wordpress from '../Wordpress';
import Landing from '../Blocks/Landing';
import SplitScreen from '../Blocks/SplitScreen';
import Hero from '../Blocks/Hero';
import Minihero from '../Blocks/Minihero';
import Kontakt from '../Blocks/Kontakt';

export default function Page(props) {

    // Get the slug of the page to render.
    let slug;
    if (props && props.match && props.match.params) slug = props.match.params.slug;
    else if (props) slug = props.slug;

    // Store the current page in this state.
    const [page, setPage] = useState();

    // Load the page data from Wordpress.
    useEffect(() => {
        (async () => {
            let page;
            if (slug === 'frontpage') page = await Wordpress.getFrontpage();
            else page = await Wordpress.getPageBySlug(slug);

            if (!page) {
                setPage('404');
            } else {
                setPage(page);
            }
        })()
    }, [slug])

    if (page) document.title = 'Joex - ' + page.title.rendered;

    return (
        <main className="blocks" style={{backgroundColor: page && page.acf && page.acf.background_color}}>
            {page && page.acf && page.acf.blocks && page.acf.blocks.map((block, index) => {
               
                if (block.acf_fc_layout === 'landing') return <Landing key={index} data={block}></Landing>
                if (block.acf_fc_layout === 'split_screen') return <SplitScreen key={index} data={block}></SplitScreen>
                if (block.acf_fc_layout === 'hero') return <Hero key={index} data={block}></Hero>
                if (block.acf_fc_layout === 'kontakt') return <Kontakt key={index} data={block}></Kontakt>
                if (block.acf_fc_layout === 'minihero') return <Minihero key={index} data={block}></Minihero>
                

            })}
            
           
        </main>
    )
}
