import React, { useState, useEffect } from 'react'
import styledComponentsCjs from 'styled-components'
import ReactGA from 'react-ga';


export default function Kontakt({data}) {
    
    
    

    const [mail, setMail] = useState('')
    const [namn, setNamn] = useState('')
    const [message, setMessage] = useState('')
    const [status, setStatus] = useState([''])
    const [timeoutID, setTimeoutID] = useState()
    const sendMail = () => {
        let url = 'https://server02.blackpixel.se:1402/send/0adb4ca66bab41e7d8f0db181397471d/9c7fcbea6df45f986c7dc4b0d7fb7c37';

        if (mail != '' && message != '') {
            

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    subject: 'Meddelande från hemsidan - Joex AB',
                    text: `
                        <h2>Ni har fått ett meddelande från '<a href="mailto:${mail}">${mail}</a>':</h2>
                        <h2>Kundens namn: ${namn}</h2>
                        <p>${message}</p>
                    `
                })
            };
            fetch(url, requestOptions)
                .then(response => response.json())
                .then(data => {

                    if (!data.success) {
                        ReactGA.event({
                            category: 'Contact',
                            action: 'Internal Server Error',
                        });
                        setStatus(['Något gick fel :( Vänligen ring oss eller maila Joex AB', 'error']);
                        console.log(data);
                    } else {
                        console.log('SENT!');
                        ReactGA.event({
                            category: 'Contact',
                            action: 'Submitted message',
                        });
                        setStatus(['Tack för ditt meddelande, vi besvarar det så fort vi kan. Ha en härlig dag önskar vi på Joex :)'])
                    }

                });
            setNamn('');
            setMail('');
            setMessage('');

        } else {
            ReactGA.event({
                category: 'Contact',
                action: 'Failed to enter form.'
            });
            setStatus(['Kontrollera så att fälten är korrekt ifyllda', 'error'])
        }
    }

    useEffect(() => {
        clearTimeout(timeoutID);
        
        if (status[0] == '') return;
        let id = setTimeout(() => {
            setStatus([''])
        }, 3500);
        setTimeoutID(id);
    }, [status])


        return (
            <Style className={`block kontakt`}>
                <div className = "the-kontakt">
                    <div className = "inner-kontakt">
                        <div className = "left">
                            <div className = "text-content">
                                <h2>Kontakt</h2>
                                {data && data.kontakt_list.map(items=>(
                                    <React.Fragment>
                                        <div className = "element">
                                            <h4>{items.kontakt_title}</h4>
                                            {items.kontakt_text.map(myp => (
                                                <p>{myp.kontakt_element}</p>
                                            ))}
                                        </div>
                                    </React.Fragment>
                                ))}
                            </div>
                        </div>
                        <div className = "right">
                        <div className = "the-form">
                                <div className = "inner-form">
                                    <h2>Mejla Oss</h2>
                                    <input type = "text" value={namn} name = "namn" onChange={(e) => setNamn(e.target.value)} placeholder = "Namn"></input>
                                    <input type = "text" value={mail} name = "email" onChange={(e) => setMail(e.target.value)} placeholder = "Kontaktinfo"></input>
                                    <textarea id="meddelande" rows="8" value={message} onChange={(e) => setMessage(e.target.value)} cols="50" name = "message" placeholder = "Meddelande"></textarea>
                                    <div className = "buttons">
                                        <a className = "button"  onClick={() => sendMail()}>SKICKA</a>
                                    </div>
                                    <p className="status" style={{color: (status[1] == 'error' ? '#B56D6D' : '#503C37')}}>{status[0]}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                   
                </div>
            </Style>
        )
     

    
   
}

const Style = styledComponentsCjs.section`
    .text-content {
        padding: 4em;
        h2 {
            position: relative;
            padding-bottom: 1em;
        }
        h2:after {
            content: '';
    position: absolute;
    bottom: 0.5em;
    left: 0;
    width: 2em;
    height: 0.05em;
    background: white;
        }
    }

    .the-kontakt {
        display: flex;
        justify-content: center;
        min-height: 50vh;

        .inner-kontakt {
            width: 100%;
            display: flex;
            flex-wrap: wrap;

            .left {
                width: 60%;
                background: #b0312a;
                color: white;
                display: flex;
                align-items: center;
            }

            .right {
                display: flex;
                width: 40%;

                .the-form {
                    padding: 4em;
                    display: flex;
                    justify-content: center;
                    /* height: 100%; */
                    align-items: center;

                    .inner-form {
                        display: flex;
                        flex-wrap: wrap;
                    }
                }
            }
        }
    }

    input,textarea {
        background: transparent;
    padding: .5em;
    border: none;
    outline: none;
    border-bottom: #3a3737 solid 1px;
    margin-top: 2em;
    color: #3a3737;
    width: 100%;
    }

    .buttons {
        text-align: right;
        width: 100%;
        margin-top: 2em;
        .button:hover {
            color: #3a3737;
        }
    }

    .element {
        margin-bottom: 2em;
    }

    .mobile & {
        .left, .right {
            width: 100%!important;
        }
    }

    .tablet & {
        .right, .left {
            width: 50%!important;
        }
    }
`