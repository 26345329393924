import React, { Component, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import styled from 'styled-components';
import CompJS from './CompJS';
import Menubar from './Blocks/Menubar';
import Wordpress from './Wordpress';
import Footer from './Blocks/Footer';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
// import Footer from './blocks/Footer';
import Example from './Pages/Example';
import Page from './Pages/Page';

function App() {

	const myItems = [];
  const [state, setState] = useState(myItems);
  useEffect(() => {
    fetch("https://joex.se/wp/wp-json/wp/v2/posts")
      .then(res => res.json())
      .then(data => {
        setState({ items: data });
        console.log(state);
      });
  }, []);

	CompJS.init();
	// Wordpress.setURL('http://localhost:8888/joex')
	Wordpress.setURL('https://joex.se/wp/')



	const changedPage = () => {
		console.log('changed page');
		window.scrollTo(0, 0)
  }
  

	return (
		<div className="App">
			<Router>
        {withRouter(ScrollToTop)}
				
				<Menubar />

				<Switch>
					<Route exact path="/style-guide" component={Example}></Route>

					<Route exact path="/:slug" component={Page} onEnter={changedPage}/>
					<Route exact path="/" onEnter={changedPage}><Page slug="frontpage"></Page></Route>
					<Route exact path="/byggsack" onEnter={changedPage}><Page slug="byggsack"></Page></Route>
					<Route exact path="/omoss" onEnter={changedPage}><Page slug="omoss"></Page></Route>
					<Route exact path="/vara-tjanster" onEnter={changedPage}><Page slug="vara-tjanster"></Page></Route>
					<Route exact path="/kontakt" onEnter={changedPage}><Page slug="kontakt"></Page></Route>
				</Switch>

				<Footer items={state.items}/>


			</Router>

			

		</div>
	);
}


class ScrollToTop extends Component {
  componentDidUpdate(prevProps) {
      if (this.props.location !== prevProps.location) {
          window.scrollTo(0, 0);
      }
  }

  render() {
      return this.props.children ? this.props.children : <></>
  }
}
export default App;
