import React, { useState, useEffect } from 'react'
import styledComponentsCjs from 'styled-components'

export default function Landing({data}) {

    
    const [current, setCurrent] = useState(0)
    const [forced, setForced] = useState(false)

    let tid = 0;
    
    if (!forced) {
        tid = setTimeout((tid) => {
            clearTimeout(tid);
            setForced(false);
            if (current >= data.slides.length - 1) setCurrent(0)
            else setCurrent(current + 1)
        }, 12000, tid)
    }

    return (
        <Style className={`block landing`}>
            
            {data.slides.map((slide, index) => (
                <div className={`slide ${index == current ? 'current' : ''}`} style={{backgroundImage: `url(${slide.image.sizes.large})`}}>
                    <div className="overlay"></div>
                    <div className="wrapper">
                        <h1 dangerouslySetInnerHTML={{__html: slide.heading}}></h1>
                        <div className="spacer s"></div>
                        <p>{slide.text}</p>
                        <div className="spacer m"></div>
                        <div className="buttons">
                            {slide.buttons && slide.buttons.map(button => (
                                <a className="button" href={button.button.url}>{button.button.title}</a>
                            ))}
                        </div>
                    </div>
                </div>
            ))}

            <div className="nav">
            {data.slides.map((slide, index) => (
                <div className={`${current == index ? 'current' : ''}`} onClick={() => {
                   
                    clearTimeout(tid);
                    setForced(index);
                    setCurrent(index);
                }}></div>
            ))}
            </div>

        </Style>
    )
}

const Style = styledComponentsCjs.section`

    min-height: 100vh;

    h1 {
        font-size: 3.125em;
    }
    .nav {
        position: absolute;
        bottom: 1em;
        left: 50%;
        transform: translateX(-50%);
        z-index: 10;
        display: flex;
        div {
            cursor: pointer;
            width: 1em;
            height: 1em;
            background-color: white;
            border-radius: 1em;
            transition: opacity 500ms;
            opacity: 0.2;

            &.current {
                opacity: 1;
            }

            &:not(:last-child) {
                margin-right: 0.5em;
            }
        }
    }

    .slide {
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
        position: absolute;
        z-index: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: white;
        text-align: center;
        opacity: 0;
        transition: opacity 2000ms;
        pointer-events: none;

        &.current {
            opacity: 1;
            pointer-events: all;
            .wrapper {
                opacity: 1;
                transform: translateY(0);
            }
        }

        .wrapper {
            width: 70em;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            transform: translateY(-10%);
            opacity: 0;
            transition: transform 1500ms, opacity 200ms;

            p {
                font-size: 1em;
                padding: 0 10em;
            }
            .buttons {
                .button:not(:last-of-type) {
                    margin-right: 1em;
                }
            }
        }

        .overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.4);
            z-index: -1;
        }
    }


    .mobile & {

        p {
            padding: 0!important;
        }
        .wrapper {
            width: 90%;

            
        }

        .nav {
            font-size: 1.5em;
        }
    }

    .tablet & {
        .wrapper {
            width: 90%;
        }
    }
    

`
