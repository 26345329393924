
let url;

export default class Wordpress {

    /**
     * 
     * @param {string} _url - The URL where the wordpress instance is installed
     */
    static setURL(_url) {
        url = _url;
    }

    /**
     * Returns the wordpress url.
     */
    static getURL() {
        return url;
    }

    /**
     * Checks cache for an endpoint and updates it if it's out of date.
     * @param {string} ep - Endpoint
     */
    static async checkCached(ep) {
        let json;
        try {
            json = await (await fetch(`${url}/wp-json${ep}`)).json();
            if (JSON.stringify(json) === localStorage.getItem(ep)) {
                // console.log(`${ep} is up to date!`);
            } else {
                console.log(`${ep} is outdated!`);
                localStorage.setItem(ep, JSON.stringify(json));
            }
        } catch(err) {
            console.error(ep);
            console.error(json);
        }
    }

    /**
     * Returns a GET request from the Wordpress API.
     * @param {string} ep - API endpoint
     */
    static async get(ep) {
        try {
            if (!url) return;
            let cached = localStorage.getItem(ep);
            this.checkCached(ep);
            if (cached) return JSON.parse(cached);
            let json = await (await fetch(`${url}/wp-json${ep}`)).json();
            // localStorage.setItem(ep, JSON.stringify(json));
            return json;
        } catch(err) {
            return undefined;
        }
    }

    /**
     * Returns the home URL for the Wordpress instance.
     */
    static async getHome() {
        return (await this.get('/')).home;
    }

    /**
     * Returns site name.
     */
    static async getName() {
        return (await this.get('/')).name;
    }

    /**
     * Returns site description.
     */
    static async getDescription() {
        return (await this.get('/')).description;
    }

    /**
     * Returns data for a menu.
     * 
     * @param {string} menu - Menu name specified when creating the menu in Wordpres.
     */
    static async getMenu(menu) {
        let m = await this.get(`/bp/menu/${menu}`);
        if (!m || m === 'false') return undefined;
        let parseMenu = (parent = 0) => {

            return m.filter(mi => mi.menu_item_parent == parent).map(mi => {
                let children = parseMenu(mi.ID);
                let item = {
                    id: mi.ID,
                    title: mi.title,
                    order: mi.menu_order,
                    parent: mi.menu_item_parent,
                    type: mi.type,
                    url: mi.url,
                    children,
                    has_children: children.length > 0,
                }

                return item;
            })
        }
        return parseMenu();
    }

    static async getLogo() {
        return (await this.get('/bp/logo')).url;
    }

    static async getSidebar(name) {
        return await this.get(`/bp/sidebar/${name}`);
    }

    static async getSidebarHTML(name) {
        let sidebar = await this.getSidebar(name);
        return sidebar.html;
    }

    static async getPages() {
        return await this.get(`/wp/v2/pages`);
    }

    static async getPageBySlug(slug) {
        let pages = await this.getPages();
        return pages.find(p => p.slug === slug);
    }

    static async getFrontpage() {
        let page = await this.get(`/bp/frontpage`);
        if (!page) return;
        return this.getPageBySlug(page.post_name);
    }

    static parseURL(url) {
        url = url.replace(this.getURL(), '');
        return url;
    }



}
