import React, { useLayoutEffect, useState, useRef, useEffect } from 'react';
import { Util } from './Util';



/**
 * Define global settings such as breakpoints etc.
 */
let settings = {
    breakpoints: {
        mobile: {
            width: 500,
            aspectRatio: 0.7
        },
        tablet: {
            width: 1000,
            aspectRatio: 1.0
        },
    },
    scale: {
        mobile: 2.5,
        tablet: 1.5
    }
}


let runtime = {
    mobile: false,
    tablet: false,
    desktop: false
}

function getBreakpoint(width, height) {
    let asp = width / height;
    runtime.mobile = false;
    runtime.tablet = false;
    runtime.desktop = false;
    if (width < settings.breakpoints.mobile.width || asp < settings.breakpoints.mobile.aspectRatio) {
        runtime.mobile = true;
    } else if (width < settings.breakpoints.tablet.width || asp < settings.breakpoints.tablet.aspectRatio) {
        runtime.tablet = true;
    } else {
        runtime.desktop = true;
    }
    return [ runtime.mobile, runtime.tablet, runtime.desktop ]
}

/**
 * Get the window size as a react hook.
 */
export function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        setTimeout(updateSize, 0)
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}


/**
 * Get the window scroll position.
 */
export function useScrollPos() {
    const [scroll, setScroll] = useState(0);
    useLayoutEffect(() => {
        function updateScroll() {
            setScroll(window.scrollY);
        }
        window.addEventListener('scroll', updateScroll);
        updateScroll();
        setTimeout(updateScroll, 0)
        return () => window.removeEventListener('scroll', updateScroll);
    }, []);
    return scroll;
}


/**
 * React hook that will define mobile, tablet and desktop booleans to be used
 * in components as a method to change the structure depending on the current
 * device screen size. Uses the breakpoints defined in the settings object.
 * 
 * Return format: [mobile, tablet, desktop]
 */
export function useDevices() {
    const [width, height] = useWindowSize();
    return getBreakpoint(width, height);
}


/**
 * Custom React hook that fires a callback when a specified element is swiped on.
 * The hook returns a React reference that can be added to a component to enable the swipe callback.
 * 
 * @param {function} cb - Callback function for when the element is clicked.
 * @param {array} watch - Array of variables to watch.
 */
export function useSwipe(cb, watch = []) {

    // Create reference for later usage.
    const ref = useRef();

    // This code should only be executed when an element in the watch array is changed.
    useEffect(() => {

        // This swipe object will store all the data for any particular swipe.
        let swipe = {}

        // First of all, handle the mousedown event, save position and time.
        function mouseDown(e) {
            swipe = {};
            swipe.down = {
                x: e.x,
                y: e.y,
                time: e.timeStamp
            }
            if (e.changedTouches) {
                swipe.down = {
                    x: e.changedTouches[0].clientX,
                    y: e.changedTouches[0].clientY,
                    time: e.timeStamp
                }
            }
        }

        // When the mouse is released. Calculate the swipe.
        function mouseUp(e) {

            // Save 'raw' data.
            swipe.up = {
                x: e.x,
                y: e.y,
                time: e.timeStamp
            }

            // If this is a mobile the raw data looks a bit different.
            if (e.changedTouches) {
                swipe.up = {
                    x: e.changedTouches[0].clientX,
                    y: e.changedTouches[0].clientY,
                    time: e.timeStamp
                }
            }

            // Calculate distance (px).
            swipe.distance = Math.sqrt(Math.pow((swipe.down.x - swipe.up.x), 2) + Math.pow((swipe.down.y - swipe.up.y), 2));
            
            // Calculate the angle (deg).
            swipe.angle = (Math.atan2(swipe.up.y - swipe.down.y, swipe.up.x - swipe.down.x) * 180.0 / Math.PI) + 90;
            if (swipe.angle < 0) swipe.angle += 360;

            // Calculate the duration (ms).
            swipe.duration = swipe.up.time - swipe.down.time;

            // Get the direction as a string for ease of use.
            if (swipe.angle > 45 && swipe.angle <= 135) swipe.direction = 'right';
            else if (swipe.angle > 135 && swipe.angle <= 215) swipe.direction = 'down';
            else if (swipe.angle > 215 && swipe.angle <= 305) swipe.direction = 'left';
            else swipe.direction = 'up';


            // Check if this is a swipe or not.
            // These values can be changed to modify the swipe 'feel'. 
            if (
                (swipe.duration > 200 && swipe.duration < 400) &&
                (swipe.distance > 100)
            ) {
                // Execute callback with the swipe as an argument.
                cb(swipe)
            }
        }

        // Setup listeners with the functions above.
        ref.current.addEventListener('mousedown', mouseDown);
        ref.current.addEventListener('mouseup', mouseUp);
        ref.current.addEventListener('touchstart', mouseDown);
        ref.current.addEventListener('touchend', mouseUp);

        // Cleanup function.
        return () => {
            ref.current.removeEventListener('mousedown', mouseDown);
            ref.current.removeEventListener('mouseup', mouseUp);
            ref.current.removeEventListener('touchstart', mouseDown);
            ref.current.removeEventListener('touchend', mouseUp);
        }
    }, [...watch]);

    // Return the reference which will be used to define on which element this swipe should be applied on.
    return ref;
}


export default class CompJS {

    /**
     * Inits the CompJS library.
     */
    static init() {



        /**
         * Update the font-size by using a custom resize function.
         */
        let resize = function(e) {
            let x = window.innerWidth;

            getBreakpoint(window.innerWidth, window.innerHeight);
        
            let w = 1800;		// "Width" / duration for the change to happen.
            let bp = 1250;		// Change m -> 0
            let s = 1;
            let scale = 1.25;


            s = (Math.sin(x * (Math.PI / w) - (Math.PI / 2) * ((bp / w * 2) - 1)) / 4)+0.75;
            
            if (runtime.mobile) s = settings.scale.mobile;
            else if (runtime.tablet) s *= settings.scale.tablet;
            if (x > bp + w) s = 0.5;

            document.body.style.fontSize = (s * scale) + 'vw';


            document.body.classList.remove('desktop')
            document.body.classList.remove('tablet')
            document.body.classList.remove('mobile')

            if (runtime.mobile) document.body.classList.add('mobile')
            if (runtime.tablet) document.body.classList.add('tablet')
            if (runtime.desktop) document.body.classList.add('desktop')

        }


        window.removeEventListener('resize', ()=>{})
        window.addEventListener('resize', resize)
        resize();

    }

}