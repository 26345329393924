import React, { useState, useEffect } from 'react'
import styledComponentsCjs from 'styled-components'

export default function Hero({data}) {
    


        return (
            <Style className={`block hero`}>
                <div className="overlay"></div>
                <div className = 'hero' style = {{backgroundImage: `url(${data.hero_image.url})`}}>
                    <div className = 'hero-content'>
                        <h1>{data.hero_text}</h1>
                        <div className="spacer s"></div>
                        <p className = "paragraph">{data.hero_paragraph}</p>
                        <div className="spacer m"></div>
                        <div className="buttons">
                        
                        {data.buttons.map((mybutton, index) => mybutton.button.url && mybutton.button.title &&(
                            <a href = {mybutton.button.url} className="button">{mybutton.button.title}</a>
                        ))}              
                                    
                        </div>
                    </div>
                </div>
    
            </Style>
        )
     

    
   
}

const Style = styledComponentsCjs.section`

position: relative;

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    z-index: 0;
}
    .hero {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 100vh;
        background-size: cover;
        background-position: center;
        align-items: center;

        .hero-content {
            text-align: center;
            z-index: 2;
            width: 75%;

            h1 {
                
                font-size: 2.95em;
                color: white;
                width: 100%;
                margin: 0 auto;
                text-align: center;
            }

           p {
                color: white;
                font-size: 1em;
                padding: 0 10em;
            }
            .mobile & {
                .paragraph {
                    padding: 0!important;
                }
                h1 {
                    width: 90%;
                }
            }
            .buttons {
              

                a:nth-child(1) {
                    margin-right: 1em;
                }
            }
        }
    }

    
`