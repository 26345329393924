import React, { useEffect, useState } from 'react'
import Wordpress from '../Wordpress';
import { Link } from 'react-router-dom';

export default function Logo() {
    
    
    const [logo, setLogo] = useState();

    useEffect(() => {
        (async () => setLogo(await Wordpress.getLogo()))()
    }, [])

    
    return <Link className="logo" to="/"><img style={{
        width: '100%', 
        height: '100%',
        objectFit: 'contain',
        objectPosition: 'center'
    }} src={logo} alt="logo"/></Link>
}
