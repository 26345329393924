import React, { useState, useEffect } from 'react'
import Logo from '../Components/Logo'
import styledComponentsCjs from 'styled-components'
import Wordpress from '../Wordpress'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { useDevices } from '../CompJS'

export default function Menubar() {

    const [menu, setMenu] = useState()
    const [socialMedia, setSocialMedia] = useState()
    const [mobile, tablet, desktop] = useDevices();
    const [open, setOpen] = useState(false)

    useEffect(() => {
        (async () => {
            setMenu(await Wordpress.getMenu('Huvudmeny'));
            setSocialMedia((await Wordpress.get(`/acf/v3/options/options`)).acf.social_media)
        })()
    }, [])

    

    return (
        <Style>
            <Logo></Logo>
            <div className="spacer"></div>
            {mobile && (
                <div className={`menu-button ${open ? 'close' : 'open'}`} onClick={() => setOpen(!open)}>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            )}
            <div className={`menu ${open ? 'open' : 'closed'}`}>
                {menu && menu.map(item => (
                    <Link onClick={() => setOpen(false)} className="item" to={Wordpress.parseURL(item.url)}>{item.title}</Link>
                ))}


                {mobile && (
                    <div className="social-media">
                        {socialMedia && socialMedia.map(icon => (
                            <a href={icon.link}>
                                <img src={icon.icon.sizes.thumbnail} alt={icon.icon.alt}/>
                            </a>
                        ))}
                    </div>
                )}
            </div>

            {!mobile && (
                <div className="social-media">
                    {socialMedia && socialMedia.map(icon => (
                        <a href={icon.link}>
                            <img src={icon.icon.sizes.thumbnail} alt={icon.icon.alt}/>
                        </a>
                    ))}
                </div>
            )}

        </Style>
    )
}


const Style = styledComponentsCjs.section`


    .menu-button {
        font-size: 3em;
        flex-shrink: 0;
        width: 1em;
        height: 1em;
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        div {
            width: 100%;
            height: 0.12em;
            background: black;
            border-radius: 1em;
            transition: 300ms transform;
        }

        &.close {
            div:nth-child(1) {
                transform: translateY(0.32em) rotate(45deg)
            }
            div:nth-child(2) {
                transform: scaleX(0)
            }
            div:nth-child(3) {
                transform: translateY(-0.32em) rotate(-45deg)
            }
        }
    }

    z-index: 100;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 2em;
    background-color: white;
    padding: 0.1em 4em;
    box-shadow: 0 0 1em rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;

    .menu {
        list-style: none;
        padding: 0;
        display: flex;
        align-items: center;
        .item:hover {
            transform: scale(1.1);
        }
        .item {
            font-weight: 100;
            text-decoration: none;
            transition: all .2s ease-in-out; 

            &:not(:last-of-type) {
                margin-right: 1em;
            }
        }
    }
    
    .social-media {
        display: flex;
        align-items: center;
        margin-left: 2em;
        a {
            font-size: 1.5em;
            img {
                width: 1em;
                height: 1em;
            }
            &:not(:last-of-type) {
                margin-right: 0.5em;
            }
        }
    }

    .logo {
        width: 6em;
        padding: 5px;
        height: 4.5em;
    }

    .spacer {
        flex-grow: 1;
    }




    .mobile & {
        .menu {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100vh;
            z-index: -1;
            background-color: var(--color-secondary);
            color: white;
            transform: translateY(100%);
            font-size: 2em;
            flex-direction: column;
            padding: 1em;
            transition: left 300ms;
            padding-top: 8em;

            &.closed {
                left: 100%;
            }

            img {
                filter: invert(1);
            }

            .item {
                margin-right: 0;
                &:not(:last-of-type) {
                    margin-bottom: 0.5em;
                }
            }

            .social-media {
                margin-left: 0;
                margin-top: 2em;
            }
        }
    }
`