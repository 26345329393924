import React from 'react'
import styled from 'styled-components'

export default function Example() {

    return (
        <Container className="contained">

            <div className="spacer xl"></div>
            <div className="spacer xl"></div>


            <h1 style={{textAlign: 'center'}}>Styleguide</h1>



            <div className="spacer l"></div>



            <div className="spacer l"></div>
            
            <h3>Font Faces</h3>
            <div className="spacer s"></div>
            
            <div className="wrapper">
                <h1>Heading 1</h1>
                <div className="spacer xs"></div>
                <h2>Heading 2</h2>
                <div className="spacer xs"></div>
                <h3>Excerpt Text</h3>
                <div className="spacer xs"></div>
                <p>Body Text<br/>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis voluptatibus corporis, at ipsum veritatis quisquam mollitia ad, quibusdam illum nesciunt magni quaerat maxime optio nisi. Eaque nesciunt sint saepe quas!</p>
            </div>
            
            


            <div className="row">
                
                <div className="column-50">
                    <div className="spacer l"></div>
                    <h3>Buttons</h3>
                    <div className="spacer s"></div>
                    <div className="wrapper">
                        <a href="#" className="button">Button</a>
                        <div className="spacer xs"></div>
                        <button className="secondary">Secondary Button</button>
                        <div className="spacer xs"></div>
                        <button className="outlined">Outlined Button</button>
                        <div className="spacer xs"></div>
                        <button className="outlined secondary">Secondary Outlined Button</button>
                        <div className="spacer m"></div>
                    </div>
                </div>


                <div className="column-50">
                    <div className="spacer l"></div>
                    <h3>Margins</h3>
                    <div className="spacer s"></div>
                    <div className="wrapper">
                        <div className="margin xl">
                            <span></span>
                            <h3>XL Margin</h3>
                        </div>
                        <div className="margin l">
                            <span></span>
                            <h3>L Margin</h3>
                        </div>
                        <div className="margin m">
                            <span></span>
                            <h3>M Margin</h3>
                        </div>
                        <div className="margin s">
                            <span></span>
                            <h3>S Margin</h3>
                        </div>
                        <div className="margin xs">
                            <span></span>
                            <h3>XS Margin</h3>
                        </div>
                    </div>
                </div>
            </div>

            <div className="spacer l"></div>


            <h3>Form Elements</h3>
            <div className="spacer s"></div>
            
            <div className="wrapper">
                <input type="text" placeholder="Textfield" />
                <input type="text" placeholder="Textfield" defaultValue="Filled Textfield" />
                <input type="text" placeholder="Textfield" defaultValue="Disabled Textfield" disabled />

                <br/>
                <div className="spacer xs"></div>

                <input type="password" placeholder="Password" />
                <input type="password" placeholder="Password" defaultValue="This is a password" />
                <input type="password" placeholder="Password" defaultValue="This is a password" disabled />

                <br/><br/>

                <input type="search" placeholder="sök..." />

                <br/><br/>

                <label className="radio">Radio Button
                    <input type="radio" name="test" />
                    <span className="checkmark"></span>
                </label>

                <br/>
                <div className="spacer xs"></div>

                <label className="radio">Radio Button Checked
                    <input type="radio" name="test" defaultChecked="checked" />
                    <span className="checkmark"></span>
                </label>


                <br/><br/>

                <label className="checkbox">Checkbox
                    <input type="checkbox" />
                    <span className="checkmark"></span>
                </label>

                <br/>
                <div className="spacer xs"></div>

                <label className="checkbox">Checkbox Checked
                    <input type="checkbox" defaultChecked="checked" />
                    <span className="checkmark"></span>
                </label>

                <br/><br/>

            </div>


            <div className="spacer xl"></div>


        
        </Container>
    )
}


const Container = styled.section`
	.margin {
		display: flex;
		align-items: center;
		margin-bottom: var(--margin-s);

		span {
			border: var(--color-primary) dashed 0.2em;
			width: var(--size);
			height: var(--size);
			display: block;
			margin-right: var(--margin-s);
		}

		&.xl { --size: var(--margin-xl); }
		&.l { --size: var(--margin-l); }
		&.m { --size: var(--margin-m); }
		&.s { --size: var(--margin-s); }
		&.xs { --size: var(--margin-xs); }
	}

	:not(.mobile) *[class*="column"] .wrapper {
		height: 100%;
	}

	.wrapper {
		--padding: var(--margin-m);
		/* background: #eee; */
		/* padding: var(--padding); */
	}

	*[class*="column"] .wrapper {
		/* margin: 0 calc(var(--padding) / 2); */
	}

	*[class*="column"]:last-of-type .wrapper {
		margin-right: 0;
	}

	*[class*="column"]:first-of-type .wrapper {
		margin-left: 0;
	}


`
