import React, { useState, useEffect } from 'react'
import styledComponentsCjs from 'styled-components'

export default function Minihero({data}) {
    
    console.log(data.minihero_color);


        return (
            <Style className={`block hero`}>
                
                <div className =  {`hero ${data.minihero_color}  `}>
                    
                    <div className = 'hero-content'>
                        <h1 className = {data.minihero_color}>{data.hero_text}</h1>
                        
                        <p className = "paragraph">{data.hero_paragraph}</p>
                        
                        <div className="buttons">
                        {data.buttons.map((mybutton, index) => mybutton.button.url && mybutton.button.title &&(
                            <a href = {mybutton.button.url} className="button">{mybutton.button.title}</a>
                        ))}              
                                    
                        </div>
                    </div>
                </div>
    
            </Style>
        )
     

    
   
}

const Style = styledComponentsCjs.section`


.background_red {
    background-color: #b0312a;
    color: white;
}
.background_white {
    background-color: white;
    color:  #3a3737;
}
.background_dark {
    background-color:  #3a3737;
    color: white;
}
    .hero {
        position: relative;
        display: flex;
        justify-content: center;
        width: 100%;
        height: 50vh;
        background-size: cover;
        background-position: center;
        align-items: center;

        .hero-content {
            text-align: center;
            z-index: 2;
            width: 75%;

            h1 {
                
                font-size: 2.8em;
                width: 100%;
                margin: 0 auto;
                text-align: center;
            }

           p {
                color: white;
                font-size: 1em;
                padding: 0 10em;
            }
            .mobile & {
                .paragraph {
                    padding: 0!important;
                }
                h1 {
                    width: 90%;
                }
            }
            .buttons {
              

                a:nth-child(1) {
                    margin-right: 1em;
                }
            }
        }
    }

    
`