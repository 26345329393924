import React, { useState, useEffect } from 'react'
import styledComponentsCjs from 'styled-components'


export default function Footer({ items }) {


 

 


        return (
           
            <Style className={`block footer`}>
            
            <div className = "the-footer">
                <div className = "inner-footer">
                    <div className = "left">
                        {items && items.map(item => (
                            
                            item && item.acf.footer_blocks && (
                                item.acf.footer_blocks.map(element => (
                                    element.left_list && (
                                        element.left_list.map(mylist => (
                                            <React.Fragment>
                                                <div className = "element">
                                        <h4>{mylist.list_title}</h4>
                                        {mylist.list_text.map(paragraph => (
                                            <p>{paragraph.list_element}</p>
                                        ))}
                                                </div>
                                            </React.Fragment>
                                        ))
                                    )
                                ))
                            )
                        ))}
                    </div>
                    <div className = "right">
                        {items && items.map(item2 => (
                            item2 && item2.acf.footer_blocks && (
                                item2.acf.footer_blocks.map(logo => (
                                    logo.logo_footer && (
                                        <React.Fragment>
                                            <div className = "element">
                                                <div className = "footer-logo">
                                                    <img src = {logo.logo_footer.url}></img>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    )
                                ))
                            )
                        ))}
                    </div>
                </div>
            </div>
               
            
                
                
            </Style>
                
               
    
         
        )
     

    
   
}

const Style = styledComponentsCjs.section`
    .the-footer {
        display: flex;
    justify-content: center;
    background: #3a3737;

    .inner-footer {
        padding: 4em;
        display: flex;
        width: 100%;
        justify-content: space-between;


        .left {
            width: 100%;
    display: flex;
    justify-content: space-around;

    .element {
        color: white;

        h4 {
            margin-bottom: 5px;
        }
    }
        }


        .right {
            display: none!important;
            width: 20%;
    
    justify-content: center;
    align-items: center;
    
    .footer-logo {
        max-width: 6em;

        img {
            width: 100%;
        }
    }
        }


    }
    }

    .mobile & {
        .inner-footer {
            flex-wrap: wrap;
        }

        div.right {
            width: 100%!important;
        }

        .left {
            flex-wrap: wrap; 
            width: 100%!important;

            .element {
                text-align: left;
                margin-bottom: 3em;
                width: 100%;
            }

             
        }
    }

    .tablet & {
        .element {
            width: 35%;
            text-align: center;
        }

        .left {
            justify-content: center;
        }
    }
`